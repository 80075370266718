<script setup>
import {Link, router, useForm, usePage} from "@inertiajs/vue3";
import AuthLayout from "@Layouts/AuthLayout.vue";
import InputError from "@Components/InputError.vue";
import InputField from "@Components/InputField.vue";
import Button from "@Components/Button.vue";
import {isNullOrEmpty, notify, socialite, useCaptcha} from "@Res/js/helpers";
import {SocialitePlatform} from "@Res/js/enums";
import {nextTick, onMounted} from "vue";
import AnchorLink from "@Components/AnchorLink.vue";
import axios from "axios";

const props = defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    _captcha: "",
});

const {execute} = useCaptcha();

const submit = async () => {
    form._captcha = await execute("login");

    try {
        form.processing = true;
        const {data} = await axios.post(route("login"), form.data());

        // Check for two-factor
        if (Boolean(data.twoFactor) === true) {
            router.visit(route("two-factor.login"));
            return;
        }

        const response = await fetch(route("ziggy-routes"), {
            headers: {Accept: "application/json"},
        });
        const json = await response.json();

        Ziggy.routes = json.routes;
        Ziggy.defaults = json.defaults;

        await nextTick();

        router.visit(route("dashboard"));
    } catch (error) {
        form.processing = false;
        form.reset("password");

        const firstErrorKey = Object.keys(
            error?.response?.data.errors ?? {}
        )[0];

        if (
            isNullOrEmpty(firstErrorKey) ||
            !firstErrorKey ||
            typeof form.data()[firstErrorKey] === "undefined"
        ) {
            notify({
                text: error?.response?.data?.message ?? error.message,
                type: "error",
            });
        } else {
            form.errors[firstErrorKey] =
                error?.response?.data.errors[firstErrorKey][0] ??
                error?.response?.data.errors[firstErrorKey];
        }
    }
};

onMounted(() => {
    if (
        typeof usePage().props.errors !== "undefined" &&
        Object.values(usePage().props.errors).length > 0
    ) {
        notify({
            text: Object.values(usePage().props.errors)[0],
            type: "error",
        });
    }
});
</script>

<template>
    <AuthLayout title="Login">
        <template #header_cta>
            <Link :href="route('register')" class="button button-primary">
                <span>Create an account</span>
            </Link>
        </template>

        <div class="border shadow-lg rounded-lg">
            <div class="p-8 xl:p-10">
                <h1
                    class="tracking-wide text-center text-gray-700 dark:text-white"
                >
                    Sign in with
                </h1>

                <div class="mt-6 flex items-center justify-between space-x-3">
                    <Button
                        class="space-x-2 !shadow-lg"
                        kind="google-alt"
                        @click.prevent="socialite(SocialitePlatform.GOOGLE)"
                    >
                        <svg class="size-5">
                            <use href="#icon-google-logo"/>
                        </svg>
                        <span>Google</span>
                    </Button>
                </div>

                <div class="relative py-4">
                    <div class="absolute inset-0 flex items-center">
                        <div class="w-full border-b border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center">
                        <span class="bg-white px-4 text-sm text-gray-500"
                        >OR</span
                        >
                    </div>
                </div>

                <form class="flex flex-col space-y-4" @submit.prevent="submit">
                    <div>
                        <InputField
                            id="email"
                            v-model="form.email"
                            autocomplete="no-email"
                            autofocus
                            class="mt-1 w-full"
                            placeholder="Email address"
                            required
                            rounded
                            type="email"
                        />
                        <InputError :message="form.errors.email" class="mt-2"/>
                    </div>

                    <div>
                        <InputField
                            id="password"
                            v-model="form.password"
                            autocomplete="current-password"
                            class="mt-1 w-full"
                            placeholder="Password"
                            required
                            rounded
                            type="password"
                        />
                        <InputError
                            :message="form.errors.password"
                            class="mt-2"
                        />
                    </div>

                    <div>
                        <InputError
                            :message="form.errors._captcha"
                            class="mt-2"
                        />
                    </div>

                    <div
                        class="flex flex-col md:flex-row space-y-8 md:space-y-0 items-center justify-end"
                    >
                        <AnchorLink
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="w-full"
                            size="sm"
                        >
                            Forgot your password?
                        </AnchorLink>

                        <Button :processing="form.processing" type="submit">
                            Log in
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </AuthLayout>
</template>
